// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-post-types-single-case-study-js": () => import("./../../../src/postTypes/single-case-study.js" /* webpackChunkName: "component---src-post-types-single-case-study-js" */),
  "component---src-post-types-single-portfolio-js": () => import("./../../../src/postTypes/single-portfolio.js" /* webpackChunkName: "component---src-post-types-single-portfolio-js" */),
  "component---src-post-types-single-service-js": () => import("./../../../src/postTypes/single-service.js" /* webpackChunkName: "component---src-post-types-single-service-js" */),
  "component---src-templates-page-about-js": () => import("./../../../src/templates/page-about.js" /* webpackChunkName: "component---src-templates-page-about-js" */),
  "component---src-templates-page-after-work-js": () => import("./../../../src/templates/page-after-work.js" /* webpackChunkName: "component---src-templates-page-after-work-js" */),
  "component---src-templates-page-blog-js": () => import("./../../../src/templates/page-blog.js" /* webpackChunkName: "component---src-templates-page-blog-js" */),
  "component---src-templates-page-case-study-js": () => import("./../../../src/templates/page-case-study.js" /* webpackChunkName: "component---src-templates-page-case-study-js" */),
  "component---src-templates-page-category-js": () => import("./../../../src/templates/page-category.js" /* webpackChunkName: "component---src-templates-page-category-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page-contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-core-web-vitals-js": () => import("./../../../src/templates/page-core-web-vitals.js" /* webpackChunkName: "component---src-templates-page-core-web-vitals-js" */),
  "component---src-templates-page-estimate-js": () => import("./../../../src/templates/page-estimate.js" /* webpackChunkName: "component---src-templates-page-estimate-js" */),
  "component---src-templates-page-home-js": () => import("./../../../src/templates/page-home.js" /* webpackChunkName: "component---src-templates-page-home-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-portfolio-js": () => import("./../../../src/templates/page-portfolio.js" /* webpackChunkName: "component---src-templates-page-portfolio-js" */),
  "component---src-templates-page-privacy-js": () => import("./../../../src/templates/page-privacy.js" /* webpackChunkName: "component---src-templates-page-privacy-js" */),
  "component---src-templates-page-recruitment-en-js": () => import("./../../../src/templates/page-recruitment-en.js" /* webpackChunkName: "component---src-templates-page-recruitment-en-js" */),
  "component---src-templates-page-recruitment-pl-js": () => import("./../../../src/templates/page-recruitment-pl.js" /* webpackChunkName: "component---src-templates-page-recruitment-pl-js" */),
  "component---src-templates-page-services-js": () => import("./../../../src/templates/page-services.js" /* webpackChunkName: "component---src-templates-page-services-js" */),
  "component---src-templates-page-tag-js": () => import("./../../../src/templates/page-tag.js" /* webpackChunkName: "component---src-templates-page-tag-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

