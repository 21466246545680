import React, { useState, createContext } from "react";

export const MainContext = createContext([{}, () => {}]);

const MainContextProvider = props => {
  const [popup, setPopup] = useState({ show: false });
  return <MainContext.Provider value={[popup, setPopup]}>{props.children}</MainContext.Provider>;
};

export default MainContextProvider;
